<template>
  <div class="wrap">
    <van-nav-bar :title="$t('page.financialRecord')">
      <van-icon
        slot="left"
        name="arrow-left"
        color="#fff"
        @click="$router.back()"
      />
    </van-nav-bar>
    <FinancialRecord />
    <!-- <van-list
      v-model="loading"
      :finished="finished"
      :finished-text="$t('Hint.notMore') + '...'"
      :loading-text="$t('Hint.loading')"
      @load="getList"
    >
      <div v-for="(item, index) in list" :key="index" class="list_item">
        <div class="title">{{ getStatusText(item.action) }}</div>
        <div class="row">
          <div class="left">{{ $t("label.amount") }}</div>
          <div class="right">{{ item.amount }}</div>
        </div>
        <div class="row">
          <div class="left">{{ $t("label.time") }}</div>
          <div class="right">{{ item.time }}</div>
        </div>
      </div>
    </van-list> -->
  </div>
</template>

<script>
import { NavBar, Icon, Toast, List } from "vant";
import { $post, $get } from "../../utils/request";
import FinancialRecord from "./components/financialRecordCom.vue";
export default {
  components: {
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,
    [List.name]: List,
    FinancialRecord,
  },
  data() {
    return {
      list: [],
      loading: true,
      finished: false,
      currentPage: 1,
      firstGet: true,
    };
  },
  created() {
    // this.getList();
  },
  methods: {
    async getList() {
      try {
        const res = await $get("/yuebao/transfers", { page: this.currentPage });
        const { data, ret, msg } = res.data;
        this.loading = false;
        if (ret == 1) {
          if (!data.hasMorePages) {
            this.finished = true;
          }
          this.currentPage++;
          if (this.firstGet) {
            this.list = data.items;
            this.firstGet = false;
            return;
          }
          this.list = [...this.list, data.items];
          return;
        }
        Toast(msg);
      } catch (error) {}
    },
    getStatusText(action) {
      switch (action) {
        case 1:
          return this.$t("status.into");
        case 2:
          return this.$t("status.out");
        default:
          return "";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.wrap {
  min-height: 100vh;
  background: #f0ebef;
}
.list_item {
  margin: calc(9rem / 16) calc(6rem / 16) 0;
  background: #fff;
  border-radius: 4px;
  padding: calc(10rem / 16) 0 calc(21rem / 16) calc(15rem / 16);
  .title {
    color: var(--theme);
    font-size: calc(18rem / 16);
    margin-bottom: calc(12rem / 16);
  }
  .row {
    display: flex;
    margin-top: calc(9rem / 16);
    &:nth-child(1) {
      margin-top: 0;
    }
    > div {
      width: 50%;
    }
    .left {
      font-size: calc(14rem / 16);
      color: #707070;
    }
    .right {
      font-size: calc(16rem / 16);
    }
  }
}
</style>